const menuItems = [
    {
        categoryName: "Appetizers",
        items: [
            {
                name: "Egg Roll",
                price: 2.49,
                description: "blah blah",
            },
            {
                name: "Fried Calimari",
                price: 2.49,
            },
            {
                name: "Vegetable Tempura",
                price: 2.49,
            },
            {
                name: "Spring Roll",
                price: 2.49,
            },
        ],
        modifiers: [
            {
                name: "Extra Sauce",
                price: 0.49,
                description: "blah blah",
            },
        ],
    },
    {
        categoryName: "Combo Dinners",
        items: {
            eggRoll: {
                name: "Egg Roll",
                price: 2.49,
            },
            chickenAndSteak: {
                name: "Chicken and Steak",
                price: 14.99,
            },
            chickenAndShrimp: {
                name: "Chicken and Shrimp",
                price: 13.99,
            },
            chickenAndSalmon: {
                name: "Chicken and Salmon",
                price: 2.49,
            },
        },
    },
    {
        categoryName: "Sushi",
        items: {
            californiaRoll: {
                name: "California Roll",
                price: 5.49,
            },
            shrimpTempuraRoll: {
                name: "ShrimpTempuraRoll Roll",
                price: 9.49,
            },
            vegeatbleRoll: {
                name: "VegetableRoll",
                price: 3.99,
            },
            eggRoll: {
                name: "Egg Roll",
                price: 2.49,
            },
        },
    },
    {
        categoryName: "Lunch",
        items: {
            egggRoll: {
                name: "Hibachi Chicken",
                price: 2.49,
            },
            eggRoll: {
                name: "Hibachi Shrimp",
                price: 2.49,
            },
            eggggRoll: {
                name: "Hibachi Salmon",
                price: 2.49,
            },
            egggggRoll: {
                name: "Egg Roll",
                price: 2.49,
            },
        },
    },
    {
        categoryName: "Dinners",
        items: {
            chicken: {
                name: "New York Strip Steak",
                price: 2.49,
            },
            steak: {
                name: "Hibachi Salmon",
                price: 2.49,
            },
            eggRoll: {
                name: "Egg Roll",
                price: 2.49,
            },
            egggRoll: {
                name: "Egg Roll",
                price: 2.49,
            },
        },
    },
    {
        categoryName: "Soba",
        items: {
            chicken: {
                name: "Chicken Soba",
                price: 2.49,
            },
            steak: {
                name: "Beef Soba",
                price: 2.49,
            },
            eggRoll: {
                name: "Yamato Soba",
                price: 2.49,
            },
            egggRoll: {
                name: "Shrimp Soba",
                price: 2.49,
            },
        },
    },
    {
        name: "Bento Boxes",
        items: {
            egggRoll: {
                name: "Chicken Bento",
                price: 2.49,
            },
            eggRoll: {
                name: "Shrimp Bento",
                price: 2.49,
            },
            eggggRoll: {
                name: "Yamato Bento",
                price: 2.49,
            },
            egggggRoll: {
                name: "Egg Roll",
                price: 2.49,
            },
        },
    },
];

const testItems = [
    {
        categoryName: "Appetizers",
        items: [
            {
                name: "Spring Rolls",
                price: 2.99,
                description:
                    "Napa Cabbage, Celery, Mushrooms, and carrots seasoned and wrapped in a shell. Fried until golden brown. Served with Duck Sauce",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-26-at-3.11.44-AM.png",
            },
            {
                name: "Egg Rolls",
                price: 2.99,
                description:
                    "Napa Cabbage, carrots, and pork. Seasoned and wrapped in a shell. Fried until golden brown. Served with Duck Sauce",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-26-at-3.11.57-AM.png",
            },
            {
                name: "Edamame",
                price: 4.99,
                description: "Japanese Soy Beans. Steamed and seasoned.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-26-at-3.10.17-AM.png",
            },
            {
                name: "Lobster Rangoon",
                price: 4.99,
                description:
                    "Cream cheese based filling wrapped in a wonton shell. Fried until golden brown. Served with Ducksauce",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/142676230_700889003787469_6117393800900869670_n.jpg",
            },
            {
                name: "Vegetable Tempura",
                price: 4.99,
                description:
                    "Fried Vegetables: Broccoli, Onions, Zucchini and Sweet Potato. Served with tempura sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/vegetable-tempura.jpg",
            },
            {
                name: "Shrimp Tempura",
                price: 6.99,
                description:
                    "3 lightly battered shrimp tempura served with tempura vegetables and tempura sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/shrimp-tempura.jpg",
            },
            {
                name: "Chicken Tempura",
                price: 5.99,
                description:
                    "Lightly battered chicken tempura served with tempura vegetables and tempura sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/chicken-tempura.jpg",
            },
            {
                name: "Sushi Appetizer*",
                price: 7.99,
                description:
                    "Nigiri sushi. 5 pc randomly assorted fish served over a ball of rice. Ginger and Wasabi included",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/sushi-appetizer.jpg",
            },
            {
                name: "Sashimi Appetizer*",
                price: 8.99,
                description:
                    "Sashimi. 5 pc randomly assorted fish. Ginger and Wasabi included",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2020/07/IMG-5479-scaled.jpg",
            },
            {
                name: "Gyoza",
                price: 6.99,
                description:
                    "Pan-fried pork dumplings, served with our house-made ponzu sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-26-at-2.24.11-AM.png",
            },
            {
                name: "Salt & Pepper Calamari",
                price: 8.99,
                description:
                    "Squid fried in tempura batter, then pan fried and seasoned to perfection. Served with onions, carrots, bell peppers.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/calimari.jpg",
            },
            {
                name: "Fried Avocado balls 4pc",
                price: 7.99,
                description:
                    "A whole avocado cut into 4 wedges and filled with our spicy tuna. Fried until golden brown. Served with our eel sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/avocado-balls.jpg",
            },
            {
                name: "Avocado fries 6pc",
                price: 6.99,
                description:
                    "Six avocado wedges fried with tempura batter until golden brown. Served with yum yum sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/avocado-fries.jpg",
            },
            {
                name: "Fried Scallops 6pc",
                price: 5.99,
                description:
                    "Fresh scallops, fried until golden brown. Served with duck sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-26-at-3.16.44-AM.png",
            },
            {
                name: "Yamato Fried Chicken Nuggets w/French Fries",
                price: 7.99,
                description:
                    "Chicken breast nuggets coated in special batter and tempura crunch. Fried until golden brown. Served with french fries and ketchup.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/chicken-with-fries.jpg",
            },
            {
                name: "Shumai",
                price: 6.99,
                description:
                    "Pan-fried shrimp dumplings, served with our house-made ponzu sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-25-at-10.45.20-PM.png",
            },
            {
                name: "Cheese Burger Egg roll (3pcs)",
                price: 5.99,
                description: "",
                imageUrl:
                    "http://johngeestudios.com/uploads/2022/07/Screen-Shot-2022-07-30-at-10.18.40-PM.png",
            },
            {
                name: "Monkey Brain",
                price: 9.99,
                description: "Shredded spicy crab and spicy salmon wrapped inside an avocado shell. Deep Fried with tempura batter. Served with a light drizzle of eel sauce.",
                imageUrl:
                    "",
            },
        ],
    },
    {
        categoryName: "Dinner Entrees",
        items: [
            {
                name: "Hibachi Vegetable",
                price: 11.59,
                description:
                    "Zucchini, onions, mushrooms, broccoli, bell peppers. Seasoned and cooked in soy sauce",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/hibachi-vegetable.jpg",
            },
            {
                name: "Hibachi Chicken",
                price: 13.59,
                description:
                    "Grilled Chicken seasoned and cooked in our house made Hibachi Sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/hibachi-chicken.jpg",
            },
            {
                name: "Hibachi Yakainku",
                price: 14.59,
                description:
                    "thinly cut ball tip steak cooked in our house made Hibachi Sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/hibachi-beef-tips.jpg",
            },
            {
                name: "Hibachi Salmon",
                price: 15.59,
                description:
                    "6oz salmon filet cooked in our house made Hibachi Sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/hibachi-salmon.jpg",
            },
            {
                name: "Sea Scallops",
                price: 16.59,
                description: "sea scallops cooked in soy sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/hibachi-scallops.jpg",
            },
            {
                name: "Jumbo Shrimp",
                price: 15.59,
                description: "10 jumbo shrimp cooked in soy sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/hibachi-shrimp.jpg",
            },
            {
                name: "Hibachi New York StripSteak",
                price: 16.59,
                description: "6 oz striploin steak cooked in soy sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/hibachi-steak.jpg",
            },
            {
                name: "New york 8oz",
                price: 17.99,
                description: "8 oz striploin steak cooked in soy sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/hibachi-newyork.jpg",
            },
            {
                name: "Filet Mignon",
                price: 18.59,
                description: "6 oz tenderloin steak cooked in soy sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/hibachi-filet.jpg",
            },
            {
                name: "Shrimp Tempura",
                price: 15.59,
                description: "7 pieces of Fried Tempura shrimp.",
                imageUrl:
                    "http://johngeestudios.com/uploads/2023/new/shrimp-tempura-dinner.jpg",
            },
            {
                name: "Hibachi Lobster",
                price: 21.59,
                description:
                    "5 oz lobster cooked in our house made butter based sauce.",
                imageUrl: "",
            },
            {
                name: "Mongolian Beef",
                price: 14.59,
                description:
                    "Ball tip steak and onions cooked in our special Spicy Mongolian Sauce.",
                imageUrl: "",
            },
            {
                name: "Mongolian Chicken",
                price: 13.59,
                description:
                    "Grilled Chicken and onions cooked in our special Spicy Mongolian Sauce.",
                imageUrl: "",
            },
            {
                name: "Sweet n Sour Chicken",
                price: 13.59,
                description:
                    "Fried Batterd Chicken. Sweet n Sour Chicken on the side.",
                imageUrl: "",
            },
            {
                name: "General Tso Chicken",
                price: 13.59,
                description: "",
                imageUrl:
                    "http://johngeestudios.com/uploads/2022/07/Screen-Shot-2022-07-30-at-10.16.45-PM.png",
            },
        ],
    },
    {
        categoryName: "Special Rolls",
        items: [
            {
                name: "Green River Roll",
                price: 13.99,
                description:
                    "Two large pieces of shrimp tempura, cream cheese, and crab. Wrapped in delicate green soy paper. Topped with a generous pinch of masago for every bite. Served with spicy mayo, and eel sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/53327499_2328750430489338_9153717743306932224_n.jpg",
            },
            {
                name: "Spicy Lava Roll",
                price: 13.99,
                description:
                    "Crab, avocado, cucumber, and shrimp. Topped with shredded spicy crab and tempura crunch mix.. Served with a drizzle of eel sauce and a small drop of sriracha for every bite.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2020/06/unnamed-3-scaled.jpg",
            },
            {
                name: "Green Dragon Roll*",
                price: 10.99,
                description:
                    "Spicy Salmon and crunch inside, Topped with Avocado and Masago.",
                imageUrl: "",
            },
            {
                name: "Rainbow Roll*",
                price: 11.99,
                description:
                    "Crab, Cucumber, and Avocado, with a variety of fish on top.",
                imageUrl: "",
            },
            {
                name: "Hot Lover Roll (Deep Fried)*",
                price: 12.99,
                description:
                    "A Variety of fish, cream cheese, avocado, and crab deep fried and served with spicy mayo and eel sauce.",
                imageUrl: "",
            },
            {
                name: "Yamato Roll (Deep Fried)*",
                price: 12.99,
                description:
                    "Spicy Salmon, Cream Cheese, Avocado, deep fried and served with spicy mayo and eel sauce.",
                imageUrl: "",
            },
            {
                name: "Dragon Roll",
                price: 12.99,
                description:
                    "Fried Shrimp Tempura and cucumber. Topped with avocado and eel. Served with eel sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/311644_265480643483004_171784240_n.jpg",
            },
            {
                name: "Volcano Roll (Deep Fried)*",
                price: 12.99,
                description:
                    "Spicy Tuna with cucumber and crab. Deep fried and served with spicy mayo and eel sauce.",
                imageUrl: "",
            },
            {
                name: "Chili Roll*",
                price: 10.99,
                description:
                    "Crab, Avocado, and cucumber, Topped with Tuna and sesame oil. Your choice of red tuna or Spicy tuna.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-30-at-1.29.19-AM.png",
            },
            {
                name: "Crazy Tuna Roll*",
                price: 15.99,
                description:
                    "Eel, Cucumber, and Avocado topped with spicy tuna, tempura flakes, Spicy sauce, and eel sauce.",
                imageUrl: "",
            },
            {
                name: "Dinosaur Roll",
                price: 14.99,
                description:
                    "Fried soft shell crab. Topped with eel and avocado. Served with eel sauce.",
                imageUrl: "",
            },
            {
                name: "Sumo Roll",
                price: 13.99,
                description:
                    "Spicy Crab, avocado, and cucumber. Topped with eel and avocado.",
                imageUrl: "",
            },
            {
                name: "Bama Crunch Roll*",
                price: 8.99,
                description:
                    "Fresh tempura crunch roll topped with salmon. Served with a light drizzle of eel sauce on top",
                imageUrl: "",
            },
            {
                name: "Gamecock Roll*",
                price: 10.99,
                description:
                    "Spicy tuna roll, Deep fried in fresh tempura batter without crunch. Garnished with eel sauce, japanese mayo, sriracha, and finely chopped green onions",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/998785_639635912734140_111296568_n.jpg",
            },
            {
                name: "Mango Paradise Roll",
                price: 11.99,
                description:
                    "Spicy crab roll that's topped with cream cheese Served with Mango, Jalapenos, Sriracha, eel sauce and fried onions",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/130751183_3856433207721045_4742131977369647284_n.jpg",
            },
            {
                name: "Jsu Roll",
                price: 12.99,
                description:
                    "Mango Paradise Roll thatâ€™s deep fried in fresh tempura batter without crunch. Served with all the same garnish and fried onions.",
                imageUrl: "",
            },
            {
                name: "Crazy Shrimp Roll",
                price: 13.99,
                description:
                    "Two large pieces of shrimp tempura with cucumber. Topped with shredded spicy shrimp mixed with tempura crunch. Served with a light drizzle of spicy mayo and eel sauce",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2020/07/IMG-5458-scaled.jpg",
            },
            {
                name: "Himalayas Roll*",
                price: 13.99,
                description:
                    "Spicy salmon, avocado, and cucumber. Topped with avocado and salmon. Garnished with a pinch of masago in every bite.",
                imageUrl: "",
            },
            {
                name: "Sweet Heart Roll",
                price: 13.99,
                description:
                    "Crab, shrimp, cream cheese, and avocado wrapped in delicate pink soy paper. Served a drizzle of spicy mayo and eel sauce.",
                imageUrl: "",
            },
            {
                name: "Tiger Roll*",
                price: 12.99,
                description:
                    "Crab, cucumber, and avocado topped with a layer of salmon. Served with a light drizzle of eel sauce.",
                imageUrl: "",
            },
            {
                name: "Hurricane Roll",
                price: 14.99,
                description:
                    "Two large pieces of shrimp tempura, unagi eel, asparagus, and avocado. Topped with mango and masago.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/141854362_3960353727328992_5513878293882764536_o.jpg",
            },
            {
                name: "Dynamite Roll*",
                price: 13.99,
                description:
                    "Two large pieces of shrimp tempura with cucumber. Topped with salmon, avocado, and jalapenos.",
                imageUrl: "",
            },
            {
                name: "Mt. Fuji Roll*",
                price: 13.99,
                description:
                    "Spicy crab roll. Topped with white tuna, avocado, and jalapenos. Serves with a light drizzle of ponzu sauce.",
                imageUrl: "",
            },
            {
                name: "Yummy Roll",
                price: 13.99,
                description:
                    "Two large pieces of shrimp tempura and cream cheese. Topped with shredded spicy crab and tempura crunch mix. Served with our signature yum yum sauce and a drizzle of eel sauce.",
                imageUrl: "",
            },
            {
                name: "Banzai Roll",
                price: 13.99,
                description:
                    "Two large pieces of shrimp tempura and cucumber. Topped with shredded spicy crab and tempura crunch mix. Served with a drizzle of eel sauce.",
                imageUrl: "",
            },
            {
                name: "Sumo Roll",
                price: 13.99,
                description:
                    "Spicy crab, avocado, and cucumber. Topped with eel and avocado. Served with a drizzle of eel sauce.",
                imageUrl: "",
            },
            {
                name: "Alabama Roll*",
                price: 13.99,
                description:
                    "Spicy crab, spicy shrimp, and cucumber. Topped with our spicy tuna and tempura crunch mix. Served with a drizzle of eel sauce.",
                imageUrl: "",
            },
            {
                name: "Roll tide Roll*",
                price: 13.99,
                description:
                    "Fried Salmon, cream cheese, and cucumber. Topped with our spicy tuna and tempura crunch mix. Served with a drizzle of eel sauce.",
                imageUrl: "",
            },
            {
                name: "Southerners Belle",
                price: 13.99,
                description:
                    "Shrimp tempura, spicy crab, cream cheese, and avocado. Deep fried to perfection. Served with spicy mayo and eel sauce.",
                imageUrl: "",
            },
            {
                name: "Southerners Roll*",
                price: 13.99,
                description:
                    "Two large pieces of shrimp tempura, cucumber, and spicy crab. Topped with salmon. Served with a drizzle of eel sauce.",
                imageUrl: "",
            },
            {
                name: "Dream Roll",
                price: 14.99,
                description:
                    "Two large pieces of shrimp tempura, cucumber, and spicy crab. Topped with salmon. Served with a drizzle of eel sauce",
                imageUrl: "",
            },
            {
                name: "Friend Roll",
                price: 12.99,
                description: "",
                imageUrl: "",
            },
            {
                name: "Valentine Roll",
                price: 12.99,
                description:
                    "Spicy Crab, Tempura Shrimp, Cream Cheese, and avocado wrapped in delicate pink soy paper. served a drizzle of spicy mayo and eel sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/02/Screen-Shot-2021-02-13-at-2.12.15-AM.png",
            },
            {
                name: "Teriyaki Beef Roll",
                price: 12.99,
                description: "Beef teriyaki, cucumber, and green onion. ",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/02/151299485_4034698496561181_6068467647425572249_o.jpg",
            },
        ],
    },
    {
        categoryName: "Soups and Salads",
        items: [
            {
                name: "Clear Soup",
                price: 1.99,
                description: "Japanese soybean broth soup",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-26-at-3.20.54-AM.png",
            },
            {
                name: "Miso Soup",
                price: 1.99,
                description:
                    "Japanese soybean broth soup. served with tofu, seaweed, mushroom, and green onions.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-26-at-3.20.36-AM.png",
            },
            {
                name: "Seafood Soup",
                price: 5.99,
                description:
                    "Japanese soybean broth, served with crab, shrimp, scallop, mushroom, broccoli, cabbage, carrots, and onions.",
                imageUrl: "",
            },
            {
                name: "Seaweed Salad",
                price: 4.99,
                description:
                    "Goma Wakame, served with the choice of with or without iceberg lettuce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-26-at-3.23.55-AM.png",
            },
            {
                name: "Green Salad",
                price: 3.99,
                description:
                    "iceberg lettuce with purple cabbage and carrots, served with our house made yum yum sauce.",
                imageUrl: "",
            },
            {
                name: "Avocado Salad",
                price: 3.99,
                description: "thinly sliced avocado served over a green salad.",
                imageUrl: "",
            },
            {
                name: "Grilled Chicken Salad",
                price: 7.99,
                description: "Teriyaki Chicken served over a green salad.",
                imageUrl: "",
            },
            {
                name: "Fried Chicken Salad",
                price: 8.99,
                description:
                    "Yamato's Chicken nuggets served over a green salad.",
                imageUrl: "",
            },
            {
                name: "Shrimp Salad",
                price: 9.99,
                description: "Teriyaki Shrimp served over a green salad.",
                imageUrl: "",
            },
        ],
    },
    {
        categoryName: "Combination Dinner",
        items: [
            {
                name: "Chicken & Vegetables",
                price: 16.99,
                description:
                    "Hibachi Chicken and Hibachi Vegetables. Includes Zucchini, onions, mushrooms, broccoli, bell peppers.",
                imageUrl: "",
            },
            {
                name: "Yakiniku & Vegetables",
                price: 16.99,
                description:
                    "Thinly cut ball tip steak and Hibachi Vegetables. Includes Zucchini, onions, mushrooms, broccoli, bell peppers.",
                imageUrl: "",
            },
            {
                name: "Shrimp & Vegetables",
                price: 17.99,
                description:
                    "6 Jumbo Shrimp and Hibachi Vegetables. Includes Zucchini, onions, mushrooms, broccoli, bell peppers.",
                imageUrl: "",
            },
            {
                name: "Chicken & Shrimp",
                price: 17.99,
                description: "6 Jumbo Shrimp and Hibachi Chicken.",
                imageUrl: "",
            },
            {
                name: "Yakiniku & Shrimp",
                price: 17.99,
                description:
                    "6 Jumbo Shrimp and thinly cut ball tip steak cooked in Hibachi Sauce",
                imageUrl: "",
            },
            {
                name: "Yakiniku & Chicken",
                price: 17.99,
                description:
                    "Hibachi Chicken and thinly cut ball tip steak cooked in Hibachi Sauce",
                imageUrl: "",
            },
            {
                name: "Sea Scallops & Shrimp",
                price: 18.99,
                description:
                    "6 Jumbo Shrimp and Sea Scallops cooked in Soy Sauce",
                imageUrl: "",
            },
            {
                name: "Filet Mignon & Shrimp",
                price: 19.99,
                description:
                    "6 Jumbo Shrimp and 4 oz tenderloin steak cooked in Soy Sauce",
                imageUrl: "",
            },
            {
                name: "Ny Strip Steak & Shrimp",
                price: 19.99,
                description:
                    "6 Jumbo Shrimp and 4 oz Striploin steak cooked in Soy Sauce",
                imageUrl: "",
            },
            {
                name: "Imperial Dinner",
                price: 19.99,
                description:
                    "Yakiniku, Chicken, and Shrimp. Cooked in Hibachi Sauce",
                imageUrl: "",
            },
            {
                name: "Filet Mignon & Chicken",
                price: 18.99,
                description:
                    "Hibachi Chicken and 4 oz tenderloin steak cooked in Soy Sauce",
                imageUrl: "",
            },
            {
                name: "New York Strip & Chicken",
                price: 18.99,
                description:
                    "Hibachi Chicken and 4 oz striploin steak cooked in Soy Sauce",
                imageUrl: "",
            },
            {
                name: "Filet Mignon & Sea Scallops",
                price: 20.99,
                description:
                    "Sea Scallops and 4 oz tenderloin steak cooked in Soy Sauce",
                imageUrl: "",
            },
            {
                name: "New York Strip & Sea Scallops",
                price: 20.99,
                description:
                    "Sea Scallops and 4 oz striploin steak cooked in Soy Sauce",
                imageUrl: "",
            },
            {
                name: "Lobster & Chicken",
                price: 26.99,
                description:
                    "Lobster and Chicken cooked in our house made sauce",
                imageUrl: "",
            },
            {
                name: "Lobster & Shrimp",
                price: 27.99,
                description:
                    "Lobster and Shrimp cooked in our house made sauce",
                imageUrl: "",
            },
            {
                name: "Lobster & Filet Mignon",
                price: 30.99,
                description:
                    "Lobster and Filet Mignon cooked in our house made sauce",
                imageUrl: "",
            },
            {
                name: "Lobster & Ny Strip Steak",
                price: 29.99,
                description:
                    "Lobster and Ny Strip Steak cooked in our house made sauce",
                imageUrl: "",
            },
        ],
    },
    {
        categoryName: "Sushi Lunch Entrees",
        isLunchCategory: true,
        items: [
            {
                name: "Sushi Lunch*",
                price: 9.99,
                description:
                    "Spicy Tuna Roll and 5pc Nigiri Sushi, Served with a small salad",
                imageUrl: "",
            },
            {
                name: "Sushi & Sashimi Lunch*",
                price: 13.99,
                description:
                    "4 pc of Nigiri Sushi, 6 pc Sashimi, and a California Roll. Served with a small salad.",
                imageUrl: "",
            },
            {
                name: "Any 2 Roll Special",
                price: 11.99,
                description:
                    "This special is only available before 3 pm. Choose Any 2 rolls from the following list. Click 'Add' to see the list",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-25-at-11.11.12-PM.png",
                modifiers: [
                    {
                        name: "Testing Testing 123, This block of code should be unreachable",
                        price: 1.0,
                        label: "First Roll",
                    },
                    {
                        name: [
                            "California",
                            "Spicy California",
                            "Spicy Tuna*",
                            "Spicy Salmon*",
                            "Spicy Crab",
                            "Eel Avocado",
                            "Eel Cucumber",
                            "Tuna*",
                            "Tuna Avocado*",
                            "Tuna Cucumber*",
                            "Salmon Roll*",
                            "Salmon Avocado*",
                            "Salmon Cucumber*",
                            "Shrimp Cucumber",
                            "Yellow Tail Scallion*",
                            "White Tuna Scallion*",
                            "Salmon Skin",
                            "Asparagus Roll",
                            "Sweet Potato",
                            "Avocado Roll",
                            "Crunch Roll",
                            "Cucumber Roll",
                            "Vegetable Roll",
                            "Jade Roll",
                        ],
                        price: 0.0,
                    },
                    {
                        name: "Testing Testing 123, This block of code should be unreachable",
                        price: 1.0,
                        label: "Second Roll",
                    },
                    {
                        name: [
                            "California ",
                            "Spicy California ",
                            "Spicy Tuna* ",
                            "Spicy Salmon* ",
                            "Spicy Crab ",
                            "Eel Avocado ",
                            "Eel Cucumber ",
                            "Tuna* ",
                            "Tuna Avocado* ",
                            "Tuna Cucumber* ",
                            "Salmon Roll* ",
                            "Salmon Avocado* ",
                            "Salmon Cucumber* ",
                            "Shrimp Cucumber ",
                            "Yellow Tail Scallion* ",
                            "White Tuna Scallion* ",
                            "Salmon Skin ",
                            "Asparagus Roll ",
                            "Sweet Potato ",
                            "Avocado Roll ",
                            "Crunch Roll ",
                            "Cucumber Roll ",
                            "Vegetable Roll ",
                            "Jade Roll ",
                        ],
                        price: 0.0,
                    },
                ],
            },
            {
                name: "Any 3 Roll Special",
                price: 15.99,
                description:
                    "This special is only available before 3 pm. Choose Any 3 rolls from the following list. Click 'Add' to see the list",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-25-at-11.10.59-PM.png",
                modifiers: [
                    {
                        name: "Testing Testing 123, This block of code should be unreachable",
                        price: 1.0,
                        label: "First Roll",
                    },
                    {
                        name: [
                            "California",
                            "Spicy California",
                            "Spicy Tuna*",
                            "Spicy Salmon*",
                            "Spicy Crab",
                            "Eel Avocado",
                            "Eel Cucumber",
                            "Tuna*",
                            "Tuna Avocado*",
                            "Tuna Cucumber*",
                            "Salmon Roll*",
                            "Salmon Avocado*",
                            "Salmon Cucumber*",
                            "Shrimp Cucumber",
                            "Yellow Tail Scallion*",
                            "White Tuna Scallion*",
                            "Salmon Skin",
                            "Asparagus Roll",
                            "Sweet Potato",
                            "Avocado Roll",
                            "Crunch Roll",
                            "Cucumber Roll",
                            "Vegetable Roll",
                            "Jade Roll",
                        ],
                        price: 0.0,
                    },
                    {
                        name: "Testing Testing 123, This block of code should be unreachable",
                        price: 1.0,
                        label: "Second Roll",
                    },
                    {
                        name: [
                            "California ",
                            "Spicy California ",
                            "Spicy Tuna* ",
                            "Spicy Salmon* ",
                            "Spicy Crab ",
                            "Eel Avocado ",
                            "Eel Cucumber ",
                            "Tuna* ",
                            "Tuna Avocado* ",
                            "Tuna Cucumber* ",
                            "Salmon Roll* ",
                            "Salmon Avocado* ",
                            "Salmon Cucumber* ",
                            "Shrimp Cucumber ",
                            "Yellow Tail Scallion* ",
                            "White Tuna Scallion* ",
                            "Salmon Skin ",
                            "Asparagus Roll ",
                            "Sweet Potato ",
                            "Avocado Roll ",
                            "Crunch Roll ",
                            "Cucumber Roll ",
                            "Vegetable Roll ",
                            "Jade Roll ",
                        ],
                        price: 0.0,
                    },
                    {
                        name: "Testing Testing 123, This block of code should be unreachable",
                        price: 1.0,
                        label: "Third Roll",
                    },
                    {
                        name: [
                            "California  ",
                            "Spicy California  ",
                            "Spicy Tuna*  ",
                            "Spicy Salmon*  ",
                            "Spicy Crab  ",
                            "Eel Avocado  ",
                            "Eel Cucumber  ",
                            "Tuna*  ",
                            "Tuna Avocado*  ",
                            "Tuna Cucumber*  ",
                            "Salmon Roll*  ",
                            "Salmon Avocado*  ",
                            "Salmon Cucumber*  ",
                            "Shrimp Cucumber  ",
                            "Yellow Tail Scallion*  ",
                            "White Tuna Scallion*  ",
                            "Salmon Skin  ",
                            "Asparagus Roll  ",
                            "Sweet Potato  ",
                            "Avocado Roll  ",
                            "Crunch Roll  ",
                            "Cucumber Roll  ",
                            "Vegetable Roll  ",
                            "Jade Roll  ",
                        ],
                        price: 0.0,
                    },
                ],
            },
        ],
    },
    {
        categoryName: "Sushi Bar Entrees",
        items: [
            {
                name: "Spicy Roll Combination Dinner*",
                price: 17.99,
                description:
                    "Spicy salmon roll, spicy tuna roll, spicy crab roll",
                imageUrl: "",
            },
            {
                name: "Special Roll Combination Dinner*",
                price: 18.99,
                description: "Rainbow Roll, and Shrimp Tempura Roll",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2020/07/IMG-5518-scaled.jpg",
            },
            {
                name: "Sushi Regular*",
                price: 16.99,
                description: "7 pieces of nigiri sushi and a california roll",
                imageUrl: "",
            },
            {
                name: "Sushi Deluxe",
                price: 18.99,
                description: "9 pieces of nigiri sushi and a california roll",
                imageUrl: "",
            },
            {
                name: "Sashimi Regular*",
                price: 21.99,
                description: "12 pieces of fish",
                imageUrl: "",
            },
            {
                name: "Sashimi Deluxe*",
                price: 24.99,
                description: "14 pieces of fish",
                imageUrl: "",
            },
            {
                name: "Sushi & Sashimi Combination*",
                price: 26.99,
                description:
                    "8 pieces of fish, 8 pieces of nigiri sushi, and 1 tuna roll",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2020/07/IMG-5522-scaled.jpg",
            },
        ],
    },
    {
        categoryName: "Sushi Rolls",
        items: [
            {
                name: "Asparagus Roll",
                price: 5.99,
                description: "asparagus only, with seaweed on outside.",
                imageUrl: "",
            },
            {
                name: "Avocado Roll",
                price: 5.99,
                description: "avocado only, with seaweed on outside.",
                imageUrl: "",
            },
            {
                name: "Cucumber Roll",
                price: 5.99,
                description: "cucumber only, with seaweed on outside.",
                imageUrl: "",
            },
            {
                name: "Sweet Potato Roll",
                price: 5.99,
                description:
                    "Fried sweet potato, served with Japanese Sushi Mayo",
                imageUrl: "",
            },
            {
                name: "Vegetable Roll",
                price: 5.99,
                description: "Avocado, asparagus, cucumber, carrot",
                imageUrl: "",
            },
            {
                name: "Vegetable California Roll",
                price: 5.99,
                description: "Asparagus, cucumber, avocado",
                imageUrl: "",
            },
            {
                name: "Alaska Roll*",
                price: 6.99,
                description: "Salmon, Cucumber, Avocado",
                imageUrl: "",
            },
            {
                name: "California Roll",
                price: 6.99,
                description: "Crab, Cucumber, Avocado",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-26-at-3.05.57-AM.png",
            },
            {
                name: "Tuna Roll*",
                price: 6.99,
                description: "yellow fin tuna, with seaweed on outside.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-30-at-1.20.11-AM.png",
            },
            {
                name: "Yellow Tail Scallion Roll*",
                price: 6.99,
                description:
                    "Pacific Yellow Tail, garnished with scallions, seaweed on outside.",
                imageUrl: "",
            },
            {
                name: "Salmon Avocado roll*",
                price: 7.99,
                description: "Salmon and Avocado",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-30-at-1.23.45-AM.png",
            },
            {
                name: "Spicy Tuna Roll*",
                price: 7.99,
                description: "Tuna with Crunch, Spicy mayo.",
                imageUrl: "",
            },
            {
                name: "Spicy Shrimp Roll",
                price: 8.99,
                description:
                    "Cucumber on the inside, with shrimp on top. Served with hot chili oil and scallions.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-30-at-1.30.53-AM.png",
            },
            {
                name: "Spicy Yellow Tail Roll *",
                price: 6.99,
                description: "YellowTail with crunch and spicy mayo",
                imageUrl: "",
            },
            {
                name: "Spicy Salmon Roll*",
                price: 7.99,
                description: "salmon with crunch and spicy mayo",
                imageUrl: "",
            },
            {
                name: "Shrimp Avocado Roll",
                price: 7.99,
                description: "Shrimp and Avocado",
                imageUrl: "",
            },
            {
                name: "Salmon Skin Roll",
                price: 5.99,
                description: "Salmon skin fried, served with eel sauce.",
                imageUrl: "",
            },
            {
                name: "Spicy Crab Stick Roll(Deep Fried)",
                price: 7.99,
                description:
                    "Crab, Cucumber, Sriracha, Served with spicy mayo, and eel sauce.",
                imageUrl: "",
            },
            {
                name: "Eel Avocado Roll",
                price: 6.99,
                description: "Eel and Avocado, served with eel sauce",
                imageUrl: "",
            },
            {
                name: "Boston Roll",
                price: 7.99,
                description: "Crab, Cucumber, Avocado, and shrimp.",
                imageUrl: "",
            },
            {
                name: "Soft shell Crab Roll",
                price: 10.99,
                description:
                    "Deep Fried Soft shell crab and cucumber, served with eel sauce",
                imageUrl: "",
            },
            {
                name: "Philly Roll*",
                price: 7.99,
                description:
                    "Smoked Salmon and Cream cheese, Garnished with scallions.",
                imageUrl: "",
            },
            {
                name: "Seafood Tempura Roll",
                price: 9.99,
                description:
                    "Chef's Choice of deep fried fish, and cucumber, served with eel sauce",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2020/07/IMG-5245-scaled.jpg",
            },
            {
                name: "Spicy California Roll",
                price: 7.99,
                description:
                    "Crab, Cucumber, and Avocado, served with Spicy Mayo",
                imageUrl: "",
            },
            {
                name: "Mexican Roll*",
                price: 8.99,
                description:
                    "Cucumber on the inside and red tuna on top, served with sesame oil and scallions.",
                imageUrl: "",
            },
            {
                name: "New York Roll",
                price: 7.99,
                description:
                    "Shrimp with Iceberg Lettuce, served with Japanese sushi Mayo.",
                imageUrl: "",
            },
            {
                name: "Spicy Crab Roll",
                price: 7.99,
                description: "Spicy Crab and Crunch, served with spicy mayo",
                imageUrl: "",
            },
            {
                name: "Tempura California Roll(Deep Fried)",
                price: 9.99,
                description:
                    "Crab, Cucumber, and Avocado, served with eel sauce, and spicy mayo",
                imageUrl: "",
            },
            {
                name: "Chicken Tempura Roll",
                price: 9.99,
                description:
                    "Chicken fried with tempura crunch, and cucumber, served with eel sauce",
                imageUrl: "",
            },
            {
                name: "Salmon Roll",
                price: 6.99,
                description: "",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-30-at-1.15.25-AM.png",
            },
            {
                name: "Big Dill Roll",
                price: 8.99,
                description:
                    "Fried pickle and Cream Cheese inside, with ranch dressing and spicy mayo on top. ",
                imageUrl:
                    "http://johngeestudios.com/uploads/2022/07/IMG_5877-e1656745159242.jpeg",
            },
        ],
    },
    {
        categoryName: "Nigiri Sushi or Sashimi",
        items: [
            {
                name: "Any Two Pieces Nigiri Sushi",
                price: 4.99,
                description: "a slice of raw fish over pressed sushi rice.",
                imageUrl: "",
            },
            {
                name: "Any Two Piece Sashimi",
                price: 4.99,
                description: "fresh raw fish sliced into thin pieces",
                imageUrl: "",
            },
        ],
    },
    {
        categoryName: "Side Orders",
        items: [
            {
                name: "Small Fried Rice",
                price: 3.99,
                description: "",
                imageUrl: "",
            },
            {
                name: "Mushrooms",
                price: 3.99,
                description:
                    "Thinly cut mushrooms cooked in our house made hibachi sauce",
                imageUrl: "",
            },
            {
                name: "Zucchini and Onions",
                price: 3.99,
                description:
                    "Grilled Zucchini and onions seasoned with salt, pepper, and soy sauce.",
                imageUrl: "",
            },
            {
                name: "Side Chicken",
                price: 5.99,
                description:
                    "Grilled Chicken and mushrooms cooked in our house made hibachi sauce",
                imageUrl: "",
            },
            {
                name: "Side Beef",
                price: 5.99,
                description:
                    "thingly cut ball tip steak and mushrooms cooked in our house made hibachi sauce",
                imageUrl: "",
            },
            {
                name: "Side Shrimp",
                price: 6.99,
                description:
                    "6 pieces of jumbo shrimp and mushrooms cooked in soy sauce",
                imageUrl: "",
            },
            {
                name: "Salmon",
                price: 8.99,
                description:
                    "4 oz salmon filet and mushrooms cooked in our house made hibachi sauce.",
                imageUrl: "",
            },
            {
                name: "Side Ny Strip Steak",
                price: 8.99,
                description:
                    "4 oz Striploin steak and mushrooms cooked in soy sauce",
                imageUrl: "",
            },
            {
                name: "Side Filet Mignon",
                price: 9.99,
                description:
                    "4 oz Tenderloin steak and mushrooms cooked in soy sauce",
                imageUrl: "",
            },
        ],
    },
    {
        categoryName: "Lunch Entrees",
        isLunchCategory: true,
        items: [
            {
                name: "Hibachi Vegetables",
                price: 8.99,
                description:
                    "Zucchini, onions, mushrooms, broccoli, and bell peppers",
                imageUrl: "",
            },
            {
                name: "Hibachi Chicken",
                price: 9.99,
                description:
                    "Grilled Chicken seasoned and cooked in our house made Hibachi Sauce.",
                imageUrl: "",
            },
            {
                name: "Hibachi Yakainku",
                price: 9.99,
                description:
                    "thinly cut ball tip steak cooked in our house made Hibachi Sauce.",
                imageUrl: "",
            },
            {
                name: "Hibachi Salmon",
                price: 11.99,
                description:
                    "3oz salmon filet cooked in our house made Hibachi Sauce",
                imageUrl: "",
            },
            {
                name: "Sea Scallops Lunch",
                price: 10.99,
                description: "sea scallops cooked in soy sauce.",
                imageUrl: "",
            },
            {
                name: "Jumbo Shrimp",
                price: 10.99,
                description: "8 jumbo shrimp cooked in soy sauce.",
                imageUrl: "",
            },
            {
                name: "Hibachi New York Stripsteak",
                price: 11.99,
                description: "4 oz striploin steak cooked in soy sauce.",
                imageUrl: "",
            },
            {
                name: "Filet Mignon",
                price: 12.99,
                description: "4 oz tenderloin steak cooked in soy sauce.",
                imageUrl: "",
            },
            {
                name: "Sweet n Sour Chicken",
                price: 9.99,
                description:
                    "Fried Batterd Chicken. Sweet n Sour Chicken on the side.",
                imageUrl: "",
            },
        ],
    },
    {
        categoryName: "Bento Boxes",
        items: [
            {
                name: "Chicken Teriyaki bento box",
                price: 12.59,
                description:
                    "Grilled Chicken seasoned then cooked in Teriyaki Sauce",
                imageUrl: "",
            },
            {
                name: "Beef Teriyaki bento box",
                price: 13.59,
                description: "Seasoned Ball tip beef cooked in Teriyaki Sauce",
                imageUrl: "",
            },
            {
                name: "Salmon Teriyaki bento box",
                price: 14.59,
                description: "7oz Salmon Filet cooked in Teriyaki Sauce",
                imageUrl: "",
            },
            {
                name: "Shrimp Teriyaki bento box",
                price: 14.59,
                description: "10 pieces of jumbo cooked in Teriyaki Sauce",
                imageUrl: "",
            },
            {
                name: "Scallop Teriyaki bento box",
                price: 14.59,
                description: "Scallops cooked in Teriyaki Sauce",
                imageUrl: "",
            },
            {
                name: "Shrimp Tempura bento box",
                price: 13.59,
                description: "5 Large Pieces of Shrimp Tempura",
                imageUrl: "",
            },
            {
                name: "Yamato Teryaki bento box",
                price: 16.99,
                description:
                    "Beef, Chicken, and Shrimp cooked in Teriyaki Sauce",
                imageUrl: "",
            },
            {
                name: "Tofu Teriyaki bento box",
                price: 12.59,
                description: "Diced Tofu Fried, Then tossed in Teriyaki Sauce",
                imageUrl: "",
            },
            {
                name: "Vegetable teriyaki bento box",
                price: 12.59,
                description:
                    "Zucchini, onions, mushrooms, broccoli, and bell peppers",
                imageUrl: "",
            },
        ],
    },
    {
        categoryName: "Fried Rice",
        items: [
            {
                name: "Chicken Fried Rice",
                price: 10.99,
                description: "Gilled Chicken, seasoned with salt and pepper. ",
                imageUrl: "",
            },
            {
                name: "Shrimp Fried Rice",
                price: 11.99,
                description:
                    "8 pieces of jumbo shrimp. lightly seasoned with soy sauce",
                imageUrl: "",
            },
            {
                name: "Beef Fried Rice",
                price: 11.99,
                description:
                    "thinly cut ball tip steak. lightly seasoned with salt and pepper.",
                imageUrl: "",
            },
            {
                name: "Yamato Fried Rice",
                price: 13.99,
                description: "Chicken, Beef, and Shrimp. ",
                imageUrl: "",
            },
            {
                name: "Plain Fried Rice (Large)",
                price: 6.99,
                description: "Rice, Stir-fried in soy sauce and green onions. ",
                imageUrl: "",
            },
            {
                name: "Plain Fried Rice (Small)",
                price: 3.99,
                description: "Rice, Stir-fried in soy sauce and green onions. ",
                imageUrl: "",
            },
            {
                name: "Vegetable Fried Rice",
                price: 9.99,
                description:
                    "Stir Fried Vegetables, Carrots, Cabbage, Onions, Broccoli, Mushroom, mixed with Fried Rice",
                imageUrl: "",
            },
        ],
    },
    {
        categoryName: "Teriyaki Rice Bowl",
        items: [
            {
                name: "Vegetable Teriyaki Rice Bowl",
                price: 9.99,
                description:
                    "Carrots, Zucchini, Onions, Broccoli, Mushrooms, cooked in Teriyaki Sauce. Served over a bowl of Fried Rice",
                imageUrl: "",
            },
            {
                name: "Chicken Teriyaki Rice Bowl",
                price: 10.99,
                description:
                    "Grilled chicken, Zucchini and Onions cooked in Teriyaki Sauce. Served over a bowl of Fried Rice. ",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/142704963_133309735294403_4797515697959386487_n.jpg",
            },
            {
                name: "Beef Teriyaki Rice Bowl",
                price: 11.99,
                description:
                    "Thinly cut ball tip steak, Zucchini and Onions cooked in Teriyaki Sauce. Served over a bowl of Fried Rice. ",
                imageUrl: "",
            },
            {
                name: "Shrimp Teriyaki Rice Bowl",
                price: 11.99,
                description:
                    "8 Jumbo Shrimp, Zucchini and Onions cooked in Teriyaki Sauce. Served over a bowl of Fried Rice. ",
                imageUrl: "",
            },
            {
                name: "Yamato Teriyaki Rice Bowl",
                price: 13.99,
                description:
                    "Beef, Chicken, Shrimp, Zucchini and Onions cooked in Teriyaki Sauce. Served over a bowl of Fried Rice. ",
                imageUrl: "",
            },
        ],
    },
    {
        categoryName: "Soba",
        items: [
            {
                name: "Plain Soba",
                price: 6.99,
                description: "",
                imageUrl: "",
            },
            {
                name: "Vegetable Soba",
                price: 8.99,
                description:
                    "Steamed Carrots, Cabbage, Onions, Broccoli, Mushroom",
                imageUrl: "",
            },
            {
                name: "Chicken Soba",
                price: 10.99,
                description: "Grilled chicken seasoned with salt and pepper",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/142808030_536958174158442_5577560214844341350_n.jpg",
            },
            {
                name: "Shrimp Soba",
                price: 11.99,
                description:
                    "8 pieces of jumbo shrimp. lightly seasoned with soy sauce",
                imageUrl: "",
            },
            {
                name: "Beef Soba",
                price: 11.99,
                description:
                    "thinly cut ball tip steak. lightly seasoned with salt and pepper.",
                imageUrl: "",
            },
            {
                name: "Yamato Soba",
                price: 13.99,
                description: "Beef, Chicken, and Shrimp",
                imageUrl: "",
            },
        ],
    },
    {
        categoryName: "Kids Menu",
        items: [
            {
                name: "Kids Hibachi Chicken",
                price: 7.99,
                description:
                    "Grilled Chicken seasoned and cooked in our house made Hibachi Sauce.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/143304669_439114660563568_2255363274694083360_n-e1611651651923.png",
            },
            {
                name: "Kids Hibachi Yakiniku",
                price: 7.99,
                description:
                    "thinly cut ball tip steak cooked in our house made Hibachi Sauce.",
                imageUrl: "",
            },
            {
                name: "Kids Shrimp",
                price: 7.99,
                description: "6 jumbo shrimp cooked in soy sauce.",
                imageUrl: "",
            },
            {
                name: "Yamato Fried Chicken",
                price: 7.99,
                description:
                    "Chicken breast nuggets coated in special batter and tempura crunch. Fried until golden brown. Served with choice of French Fries, or Fried Rice",
                imageUrl: "",
            },
        ],
    },
    {
        categoryName: "Poke Bowl/Sushi Burrito",
        items: [
            {
                name: "Poke Bowl",
                price: 12.99,
                description:
                    "Custom Poke Bowl made with delicious ingredients commonly found in sushi rolls.  ",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/IMG_9741-e1611650442498.png",
            },
            {
                name: "Sushi Burrito",
                price: 12.99,
                description:
                    "Custom made Burrito made from Nori and filled with delicious ingredients commonly found in sushi rolls.  ",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/IMG_9740-e1611650529540.png",
            },
        ],
    },
    {
        categoryName: "Asian Specials",
        items: [
            {
                name: "Honey Chicken",
                price: 13.59,
                description:
                    "Battered Chicken, deep fried. Then tossed in our house-made sweet and savory honey sauce. Served with fried rice, zucchini, onions, miso soup, and salad. ",
                imageUrl: "",
            },
            {
                name: "Sesame Chicken",
                price: 13.59,
                description:
                    "Battered Chicken, deep fried. Then tossed in our house-made sweet and savory general tsÃ³ sauce.Garnished with sesame seeds. Served with fried rice, zucchini, onions, miso soup, and salad. ",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/143029606_1117577915392503_2864120383136258488_n.jpg",
            },
            {
                name: "General TsÃ³ Tofu",
                price: 13.59,
                description:
                    "Diced tofu fried to perfection. Tossed in our house-made sweet and savory spicy general tsÃ³ sauce. Served with fried rice, zucchini, onions, miso soup, and salad.",
                imageUrl: "",
            },
            {
                name: "Vegetable Mei Fun",
                price: 9.99,
                description:
                    "Stir-Fried thin rice noodles. Seasoned to perfection. Served with carrots, cabbage, onions, broccoli, mushrooms, and egg",
                imageUrl: "",
            },
            {
                name: "Chicken Mei Fun",
                price: 10.99,
                description:
                    "Grilled Chicken and Stir-Fried thin rice noodles. Seasoned to perfection. Served with carrots, cabbage, onions, broccoli, mushrooms, and egg",
                imageUrl: "",
            },
            {
                name: "Beef Mei Fun",
                price: 11.99,
                description:
                    "Thinly cut ball tip steak with Stir-Fried thin rice noodles. Seasoned to perfection. Served with carrots, cabbage, and onions and egg",
                imageUrl:
                    "http://johngeestudios.com/uploads/2020/06/Screen-Shot-2022-07-30-at-10.22.56-PM.png",
            },
            {
                name: "Shrimp Mei Fun",
                price: 11.99,
                description:
                    "8 Jumbo Shrimp Stir-Fried thin rice noodles. Seasoned to perfection. Served with carrots, cabbage, and onions and egg",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-30-at-1.35.07-AM.png",
            },
            {
                name: "Singapore Mei Fun",
                price: 13.99,
                description:
                    "Beef, Chicken, and Shrimp, and Stir-Fried thin rice noodles. Seasoned to perfection with Curry and Sriracha. Served with carrots, cabbage, and onions and egg",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/143179002_170136301178049_1381248105647155038_n.jpg",
            },
            {
                name: "Yamato Mei Fun",
                price: 13.99,
                description:
                    "Chicken, Beef and Shrimp with Stir-Fried thin rice noodles. Seasoned to perfection. Served with carrots, cabbage, and onions and egg",
                imageUrl: "",
            },
            {
                name: "Vegetable Pad Thai",
                price: 9.99,
                description:
                    "Carrots, Cabbage, Onions, Broccoli, and Mushrooms with Dried rice noodles, rehydrated and stir fried with eggs, onions, scallions, carrots, bean sprouts and pad Thai sauce. Served with chopped roasted peanuts and lime wedges",
                imageUrl: "",
            },
            {
                name: "Chicken Pad Thai",
                price: 10.99,
                description:
                    "Grilled Chicken with Dried rice noodles, rehydrated and stir fried with eggs, onions, scallions, carrots, bean sprouts and pad Thai sauce. Served with chopped roasted peanuts and lime wedges.",
                imageUrl: "",
            },
            {
                name: "Beef Pad Thai",
                price: 11.99,
                description:
                    "Thinly cut ball tip steak with Dried rice noodles, rehydrated and stir fried with eggs, onions, scallions, carrots, bean sprouts and pad Thai sauce. Served with chopped roasted peanuts and lime wedges.",
                imageUrl: "",
            },
            {
                name: "Shrimp Pad Thai",
                price: 11.99,
                description:
                    "8 Jumbo Shrimp with Dried rice noodles, rehydrated and stir fried with eggs, onions, scallions, carrots, bean sprouts and pad Thai sauce. Served with chopped roasted peanuts and lime wedges.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-25-at-10.39.15-PM.png",
            },
            {
                name: "Yamato Pad Thai",
                price: 13.99,
                description:
                    "Beef, Chicken, and Shrimp with Dried rice noodles, rehydrated and stir fried with eggs, onions, scallions, carrots, bean sprouts and pad Thai sauce. Served with chopped roasted peanuts and lime wedges.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-25-at-10.39.15-PM.png",
            },
        ],
    },
    {
        categoryName: "Boba Milk Teas",
        items: [
            {
                name: "Yamato's Milk Tea",
                price: 4.5,
                description:
                    "Taiwanese inspired recipe which combines tea base with milk and various fruit flavoring. ",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/IMG_9739.jpg",
            },
        ],
    },
    {
        categoryName: "Boba Fruit Teas",
        items: [
            {
                name: "Yamato's Fruit Tea",
                price: 4.5,
                description:
                    "Taiwanese inspired recipe which combines a tea base with various fruit flavors",
                imageUrl: "",
            },
        ],
    },
    {
        categoryName: "Drinks",
        items: [
            {
                name: "Soft Drink",
                price: 2.99,
                description: "32oz To go drinks",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/02/Screen-Shot-2021-02-10-at-12.06.21-AM.png",
            },
        ],
    },
    {
        categoryName: "Dessert",
        items: [
            {
                name: "Sugar Doughnut",
                price: 4.99,
                description: "Fried doughnuts with the sugary coat. 10pcs",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-26-at-2.35.05-AM.png",
            },
            {
                name: "Fried Cheese Cake",
                price: 5.99,
                description:
                    "Rich smooth cheesecake, with a slight tangy finish, rolled in flaky pastry tortilla.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-26-at-2.33.11-AM.png",
            },
            {
                name: "Tiramisu",
                price: 4.99,
                description:
                    "Made with ladyfinger cookies, espresso, mascarpone cheese, and cocoa powder. ",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-26-at-2.29.53-AM.png",
            },
            {
                name: "Fried Oreo",
                price: 5.99,
                description: "6pc classic oreo covered in our tempura batter.",
                imageUrl:
                    "http://johngeestudios.com/uploads/rpress/2021/01/Screen-Shot-2021-01-26-at-2.28.03-AM.png",
            },
        ],
    },
    {
        categoryName: "Sauces",
        items: [
            {
                name: "Side Sauce",
                price: 1.0,
                description: "Side container of any sauce",
                imageUrl: "",
            },
        ],
    },
];
export { testItems, menuItems };
